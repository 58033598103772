<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Type</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Type"
              >
                <v-select
                  v-model="type"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None"
                  :options="typeOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>{{type}} Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="name"
              >
                <b-form-input
                  v-model="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>{{type}} Description</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Description"
              >
                <b-form-textarea
                  v-model="description"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col md="4">
            <b-form-group>
              <label>Image</label>
              <b-form-file
                v-model="image"
                placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleChangeFile($event, 'offer', 'image')"
                accept="image/*"
              />
            </b-form-group>
            <attachment :data="image" />
          </b-col>
          <b-col md="4" v-if="type && type !== 'News'">
              <b-form-group>
                <label>{{ type }} For</label>
                <label style="color: red !important">*</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Offer For"
                >
                  <v-select
                    v-model="offerfor"
                    label="name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="None"
                    @input="handleOfferFor"
                    :options="['Project', 'Land' ,'Other']"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          <b-col md="4" v-if="offerfor == 'Project'">
              <b-form-group>
                <label>Select Project</label>
                <label style="color: red !important">*</label>
                <validation-provider #default="{ errors }" rules="required" name="Project">
                  <v-select v-model="project" placeholder="None" label="projectname" multiple :options="projectOption"   :close-on-select="false"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
          </b-col>

            <b-col md="4" v-if="offerfor == 'Land'">
                <b-form-group>
                  <label>Select Land (New Survay No)</label>
                  <label style="color: red !important">*</label>
                  <validation-provider #default="{ errors }" rules="required" name="Land">
                    <v-select v-model="project" placeholder="None" label="village_name" multiple :options="villageOption">
                      <template #option="{ village_name, surveynonew }">
                    <span class="font-weight-bolder"> {{ village_name }} </span><span>({{ surveynonew }})</span>
                  </template>
                  </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
            </b-col>
          <b-col md="4" v-if="type!=='News'">
            <b-form-group>
              <label>Start Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Start Date"
              >
                <flat-pickr
                  v-model="startdate"
                  class="form-control"
                  :config="{
                    enableTime: false,
                    dateFormat: 'd/m/Y',
                  }"
                  style="background: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if=" type!=='News'">
            <b-form-group>
              <label>End Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="End Date"
              >
                <flat-pickr
                  v-model="enddate"
                  class="form-control"
                  :config="{
                    enableTime: false,
                    dateFormat: 'd/m/Y',
                    minDate: startdate,
                  }"
                  style="background: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4" v-if="type == 'Offer' || type == 'Incentive Offer'">
            <b-form-group>
              <label v-if="type == 'Offer'">Discount(%)</label>
              <label v-else >Insentive(%)</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="discount"
              >
                <b-form-input
                  v-model="value"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Discount"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

            <!-- <b-col md="4">
              <b-form-group>
                <label>Assign to</label>
                <v-select v-model="assignto" :options="['Channel Partner','Franchise','Both']" placeholder="None"/>
              </b-form-group>
            </b-col> -->


          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm($event)"
              :disabled="flag"
              class="mr-4"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import Attachment from "@/components/Attechment.vue";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormFile,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from '@/components/axios';
import moment from 'moment'

export default {
  components: {
    PinchScrollZoom,
    flatPickr,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BFormFile,
    Attachment
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      type: "",
      image:'',
      name:'',
      startdate:null,
      enddate:null,
      value:0,
      typeOptions: ["Offer","Scheme","News","Incentive Offer" ],
      description: "",
      project: [],
      projectOption: [],
      assignto:'Both',
      flag: false,
      ifEdit: false,
      projectOption:[],
      villageOption: [],
      offerfor: '',
      project_ids:[]
    };
  },
  async mounted() {
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      this.$router.push("/");
    }
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && await this.getEditValue();
    this.getProject()
    this.getVillage()
  },

  methods: {
    handleOfferFor() {
      this.project=[]
      this.$forceUpdate()
    },
    async getVillage() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getvillagedata`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.resObj = {}
          this.villageOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getProject() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getProject`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.projectOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleChangeFile(e, type, name) {
      this.flag = true;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };

      await axios(requestOptions)
        .then((response) => {
          this.image =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        })
        .catch((error) => console.log(error, "error"))
        .finally(()=>{
          this.flag = false;
        })
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/get-offer-scheme-By-Id/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
        this.type=item.type
        this.name=item.name
        this.startdate=item.startdate?moment(item.startdate).format('DD/MM/yyyy'):''
        this.enddate=item.enddate?moment(item.enddate).format('DD/MM/yyyy'):''
        this.value=item.value
        this.image=item.image
        this.description=item.description
        this.project_ids = item.project_id
        this.assignto = item.assignto
        this.offerfor = item.offerfor
        this.project = this.offerfor == 'Project' ? item.projects :item.lands

      if (this.offerfor == 'Land') {
        this.project.map((item) => {
          item.village_name = item.villagename?item.villagename.villagename:''
        })
      }
    },
    onClickBack() {
      this.$router.push("/setup/offer");
    },
    submitForm(e) {
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {

        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          let project_id = this.project.map((item) => item.id);
            project_id=project_id.join(',')
          const data = {
            type: this.type,
            name: this.name,
            startdate: this.startdate,
            enddate: this.enddate,
            value: this.value,
            image: this.image,
            description: this.description,
            project_id: project_id,
            assignto: this.assignto,
            offerfor:this.offerfor
          };
          this.flag = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/offer-scheme/${this.$route.params.id}`
              : `${baseApi}/offer-scheme`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((response) => {
              this.flag = false;

              this.$router.push("/setup/offer");
              this.$swal({
                title: "Submited",
                text: response.data.message
                  ? `${response.data.message}`
                  : response.data.success
                  ? `${response.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.flag = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>
